<template>
  <div>
    <Disclosure
      v-slot="{ open }"
      as="nav"
      :class="admin ? 'border-b-2 border-rose-500 bg-rose-50' : 'bg-white'"
      class="fixed left-0 top-0 z-20 w-full border-b border-gray-200"
    >
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="flex h-16">
          <div class="flex flex-1">
            <div class="flex flex-1 items-center">
              <slot name="back">
                <!-- Fallback content -->
                <div class="mr-4 w-5" />
              </slot>
              <div class="flex w-full select-none items-center justify-center">
                <slot name="title">
                  <!-- Fallback content -->
                  <img
                    class="block h-8 w-auto"
                    src="~/assets/logo192.png"
                    alt="HomeKiq"
                  />
                  <span class="-ml-1 text-lg">OMEKIQ</span>
                </slot>
              </div>
            </div>
          </div>
          <div class="hidden sm:ml-6 sm:flex sm:items-center">
            <!-- Profile dropdown desktop -->
            <Menu as="div" class="relative ml-3">
              <div>
                <MenuButton
                  class="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span class="sr-only">Open user menu</span>
                  <UserAvatar
                    :image="data?.user?.image"
                    class="h-8 w-8 rounded-full"
                  />
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <MenuItem
                    v-for="menuItem in items"
                    :key="menuItem.title"
                    v-slot="{ active, close }"
                  >
                    <div
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      ]"
                      @click="
                        () => {
                          close();
                          menuItem.action();
                        }
                      "
                    >
                      {{ menuItem.title }}
                    </div>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
          <div class="-mr-2 flex items-center sm:hidden">
            <!-- Mobile menu button -->
            <DisclosureButton
              class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
            >
              <span class="sr-only">Open main menu</span>
              <EllipsisVerticalIcon
                v-if="!open"
                class="block h-6 w-6 text-gray-600"
                aria-hidden="true"
              />
            </DisclosureButton>
          </div>
        </div>
      </div>

      <DisclosurePanel class="sm:hidden">
        <Dialog as="div">
          <DialogPanel
            class="fixed inset-y-0 left-0 z-50 w-full overflow-y-auto bg-black/70 sm:hidden sm:max-w-sm sm:px-6 sm:ring-1 sm:ring-gray-900/10"
          >
            <div class="mr-24 h-full border-gray-200 bg-white pb-3 pt-4">
              <div class="flex h-full flex-col">
                <div class="flex items-center border-b px-4 pb-4 shadow-sm">
                  <div class="flex-shrink-0">
                    <UserAvatar class="h-10 w-10" :image="data?.user?.image" />
                  </div>
                  <div class="ml-3">
                    <UserName
                      :user="data.user"
                      class="text-base font-medium text-gray-800"
                    />
                    <div class="break-all text-sm font-medium text-gray-500">
                      {{ data.user.email }}
                    </div>
                  </div>
                  <div class="flex-1" />
                  <DisclosureButton
                    class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                  >
                    <XMarkIcon class="block h-6 w-6" aria-hidden="true" />
                  </DisclosureButton>
                </div>
                <!-- Mobile Menu -->
                <div class="mt-3 space-y-1">
                  <DisclosureButton
                    v-for="menuItem in items"
                    :key="menuItem.title"
                    as="a"
                    href="#"
                    class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                    @click.prevent.stop="menuItem.action()"
                  >
                    {{ menuItem.title }}
                  </DisclosureButton>
                </div>
                <!-- Footer -->
                <div class="flex flex-1" />
                <div v-if="showTechDetails" class="mb-2">
                  <LazyTechStatsMemory />
                  <LazyTechStatsNetwork />
                  <ClientOnly>
                    <div v-if="$pwa && $pwa.needRefresh">
                      <a
                        href="#"
                        class="font-medium text-indigo-500"
                        @click="$pwa.updateServiceWorker(true)"
                        >Reload</a
                      >
                    </div>
                  </ClientOnly>
                </div>
                <div class="flex flex-col items-baseline px-4 text-gray-500">
                  <div>HomeKiq</div>
                  <div class="flex w-full items-center text-xs">
                    <div class="flex-1 font-light text-gray-300">
                      Built on: {{ date }}
                    </div>
                    <AdjustmentsHorizontalIcon
                      class="h-5 w-5 text-indigo-400"
                      @click="showTechDetails = !showTechDetails"
                    />
                  </div>
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </DisclosurePanel>
    </Disclosure>
    <ModalContainer v-if="showTermsModal">
      <div class="p-4">
        <div class="flex flex-col gap-4">
          <div class="flex flex-col gap-3 leading-6 text-gray-700">
            <p><TermsOfUse /></p>
          </div>
        </div>
        <div class="flex gap-2">
          <button
            type="button"
            class="text-md mt-8 w-full items-center gap-3 rounded-md border bg-white px-3 py-2 font-semibold text-gray-500 shadow hover:bg-gray-50"
            @click.prevent="showTermsModal = false"
          >
            Close
          </button>
        </div>
      </div>
    </ModalContainer>
  </div>
</template>

<script setup lang="ts">
import { isAndroid } from "../lib/Utils";
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import {
  EllipsisVerticalIcon,
  XMarkIcon,
  AdjustmentsHorizontalIcon,
} from "@heroicons/vue/24/outline";
import { useShare } from "@vueuse/core";
import { ref } from "vue";

const { share, isSupported } = useShare();

defineProps<{ admin?: boolean }>();

function startShare() {
  share({
    title: "HomeKiq",
    text: "Get the HomeKiq application and simplify home management",
    url: "https://homekiq.com?utm_source=app&utm_medium=share&utm_campaign=share_button&download=true",
  });
}

const { signOut, data } = useAuth();
const date = useAppConfig().buildDate;
const showTechDetails = ref(false);

const showTermsModal = ref(false);

function showDTermsDialog() {
  showTermsModal.value = true;
}

const logout = () => {
  if (
    isAndroid() &&
    window.hasOwnProperty("mobile") &&
    window.mobile.userSignedOut !== undefined
  ) {
    window.mobile.userSignedOut();
  }

  signOut();
};

const items = [
  { title: "Your Profile", action: () => navigateTo("/profile") },
  { title: "Earn 💰", action: () => navigateTo("/earn") },
  { title: "Terms & Conditions", action: () => showDTermsDialog() },
  { title: "Sign out", action: () => logout() },
];

if (isSupported.value) {
  items.splice(2, 0, { title: "Share", action: () => startShare() });
} else if (isAndroid() && window.hasOwnProperty("mobile")) {
  items.splice(2, 0, {
    title: "Share",
    action: () => window.mobile.shareApp(),
  });
}
</script>
